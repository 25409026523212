﻿.linkwatchingsubscriptions-index, .linkwatchingsubscriptions-bookmarks {
    .visualChange {
        max-width: 100%;
       

        img {
            max-width: 100%;
            :hover {
                cursor: pointer;
            }
        }
        img:hover {
            cursor: pointer;
        }
    }

    table {
        max-width: 100%;
        table-layout: fixed;
    }

    .dont-break-out {
        white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        white-space: pre-wrap; /* css-3 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
        word-break: break-all;
        white-space: normal;
    }
     
    .urlUpdate > div {
        max-width: 100%;
    }
    
    .link-card {
        a {
            h6:hover {
                text-decoration:underline;
                color: $primary;
            }
        }
    }
}
