// Badge
$badge-sm-padding:                  .45em .775em !default;
$badge-sm-font-size:                .65em !default;
$badge-md-padding:                  .65em 1em !default;
$badge-lg-padding:                  .85em 1.375em !default;
$badge-inline-margin-right:         .625rem !default;
$badge-inline-span-top:             2px !default;
$badge-btn-margin:                  .5rem !default;

// Badge Circle
$badge-circle-border-radius:        50% !default;
$badge-circle-width:                1.25rem !default;
$badge-circle-height:               1.25rem !default;
$badge-circle-font-size:            .75rem !default;
$badge-circle-font-weight:          600 !default;

$badge-circle-md-width:             1.5rem !default;
$badge-circle-md-height:            1.5rem !default;

$badge-circle-lg-width:             2rem !default;
$badge-circle-lg-height:            2rem !default;

//Badge Dot
$badge-dot-icon-width:              .375rem !default;
$badge-dot-icon-height:             .375rem !default;
$badge-dot-icon-radius:             50% !default;
$badge-dot-icon-margin-right:       .375rem !default;

$badge-dot-md-icon-width:           .5rem !default;
$badge-dot-md-icon-height:          .5rem !default;

$badge-dot-lg-icon-width:           .625rem !default;
$badge-dot-lg-icon-height:          .625rem !default;

//Badge Floating
$badge-floating-top:                 -50% !default;
$badge-floating-border:              3px !default;
$badge-floating-transform:           translate(147%, 50%) !default;
