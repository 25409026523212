/*!
Simple-DataTables
Github: https://github.com/fiduswriter/Simple-DataTables
*/


.dataTable-wrapper.no-header .dataTable-container {
	border-top: 1px solid #d9d9d9;
}

.dataTable-wrapper.no-footer .dataTable-container {
	border-bottom: 1px solid #d9d9d9;
}

.dataTable-top,
.dataTable-bottom {
	padding: 8px 10px;
}

.dataTable-top > nav:first-child,
.dataTable-top > div:first-child,
.dataTable-bottom > nav:first-child,
.dataTable-bottom > div:first-child {
	float: left;
}

.dataTable-top > nav:last-child,
.dataTable-top > div:last-child,
.dataTable-bottom > nav:last-child,
.dataTable-bottom > div:last-child {
	float: right;
}

.dataTable-selector {
	padding: 6px;
}

.dataTable-input {
	padding: 6px 12px;
}

.dataTable-info {
	margin: 7px 0;
}

/* PAGER */
.dataTable-pagination ul {
	margin: 0;
	padding-left: 0;
}

.dataTable-pagination li {
	list-style: none;
	float: left;
}

.dataTable-pagination a {
	border: 1px solid transparent;
	float: left;
	margin-left: 2px;
	padding: 6px 12px;
	position: relative;
	text-decoration: none;
	color: #333;
}

.dataTable-pagination a:hover {
	background-color: #d9d9d9;
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
	background-color: #d9d9d9;
	cursor: default;
}

.dataTable-pagination .ellipsis a,
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
	cursor: not-allowed;
}

.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
	cursor: not-allowed;
	opacity: 0.4;
}

.dataTable-pagination .pager a {
	font-weight: bold;
}

/* TABLE */
.dataTable-table {
	max-width: 100%;
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
}

.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
	vertical-align: top;
	padding: 8px 10px;
}

.dataTable-table > thead > tr > th {
	vertical-align: bottom;
	text-align: left;
	border-bottom: 1px solid #d9d9d9;
}

.dataTable-table > tfoot > tr > th {
	vertical-align: bottom;
	text-align: left;
	border-top: 1px solid #d9d9d9;
}

.dataTable-table th {
	vertical-align: bottom;
	text-align: left;
}

.dataTable-table th a {
	text-decoration: none;
	color: inherit;
}

.dataTable-sorter {
	display: inline-block;
	height: 100%;
	position: relative;
	width: 100%;
}

.dataTable-sorter::before,
.dataTable-sorter::after {
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	right: 4px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	opacity: 0.2;
}

.dataTable-sorter::before {
	border-top: 4px solid #000;
	bottom: 0px;
}

.dataTable-sorter::after {
	border-bottom: 4px solid #000;
	border-top: 4px solid transparent;
	top: 0px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
	opacity: 0.6;
}

.dataTables-empty {
	text-align: center;
}

.dataTable-top::after, .dataTable-bottom::after {
	clear: both;
	content: " ";
	display: table;
}
