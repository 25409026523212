$dark-version-body-color:                 rgba(255, 255, 255, .8) !default;
$dark-version-bg-color:                   #141728 !default;
$dark-version-sidenav-bg-color:           #111322 !default;
$dark-version-card-bg-color:              #111322 !default;
$dark-version-card-box-shadow:            0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .20), 0 1px 5px 0 rgba(0, 0, 0,  .12) !default;
$dark-version-input-bg-image:             linear-gradient(0deg, #e91e63 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, rgba(210, 210, 210, .6)  1px, rgba(209, 209, 209, 0) 0) !default;
$dark-version-border-color:               rgba(255, 255, 255, .4) !default;
$dark-version-table-color:                rgba(255, 255, 255, .6) !default;
$dark-version-caret-sidebar-color:        rgba(206, 212, 218, .7) !default;
$dark-nav-link-active-shadow:             0 0 2rem 0 rgba(0, 0, 0, .15) !default;
