//
// Dot badge
//

// General styles

.badge-dot {
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  text-transform: none;

  strong {
    color: $gray-800;
  }

  i {
    display: inline-block;
    vertical-align: middle;
    width: $badge-dot-icon-width;
    height: $badge-dot-icon-height;
    border-radius: $badge-dot-icon-radius;
    margin-right: $badge-dot-icon-margin-right;
  }

  &.badge-md {
    i {
      width: $badge-dot-md-icon-width;
      height: $badge-dot-md-icon-height;
    }
  }

  &.badge-lg {
    i {
      width: $badge-dot-lg-icon-width;
      height: $badge-dot-lg-icon-height;
    }
  }
}
